import React from "react"
import Markdown from "react-markdown"
import Layout from "../layout"
import SEO from "../seo"
import InfoBlue from "../../assets/info_icon_blue.svg"

import FullWidthSection from "../layout/fullWidthSection"
import InnerContentContainer from "../layout/innerContentContainer"
import ResourceCategoryThumbList from "./resourceCategoryThumbList"
import { defaultRenderers } from "../../constants/mdxRenderers"

const SafetySignsIndexPage = ({
  title,
  topblurb,
  titleseo,
  description,
  categories,
  resources,
  richText,
}) => (
  <Layout>
    <SEO title={titleseo} description={description} />
    <FullWidthSection style={{ paddingBottom: "1rem", paddingTop: "3rem" }}>
      <InnerContentContainer center>
        <h1>{title}</h1>
        <div className="collection-callout">
          <img src={InfoBlue} alt="check mark" />
          <h4>
            {`These downloadable PDF signs are part of our `}
            <a href="/tools/diy-safety-system/">Free DIY Safety System</a>{" "}
            Collection
          </h4>
        </div>
        <h4>{topblurb}</h4>
      </InnerContentContainer>
    </FullWidthSection>

    <FullWidthSection>
      <InnerContentContainer>
        <Markdown
          children={richText}
          components={defaultRenderers}
        />
      </InnerContentContainer>
    </FullWidthSection>
    <FullWidthSection style={{ paddingTop: "2rem" }}>
      <InnerContentContainer center>
        {categories.map(category => {
          const filteredResources = resources.filter(
            resource => resource.resource_category.Title === category.Title
          )
          return (
            <ResourceCategoryThumbList
              signs
              title={category.Title}
              resources={filteredResources}
            />
          )
        })}
      </InnerContentContainer>
    </FullWidthSection>
  </Layout>
)

export default SafetySignsIndexPage
